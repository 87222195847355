@import url(https://fonts.googleapis.com/css2?family=Albert+Sans:ital,wght@0,400;0,500;0,700;0,800;1,400;1,500;1,700;1,800&display=swap);


* {
  font-family: 'Albert Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
}

html {
  height: 100vh;
}

body,
#root {
  height: 100%;
}

*:focus {
  outline: none;
}

*:active {
  outline: none;
}

